/**
 * Muestra un loader en un boton y llama a una funcion con el detenedor como metodo
 *
 * @author Sebastian Findling
 */

/**
 * Modo de uso:
 *
 * [Importación en componente Vue]
 *   import btnLoader from '../../btnLoader'
 *
 * [Iniciación]
 *   async mounted () {
 *     ...
 *     this.btnLoader = btnLoader(this)
 *   }
 *
 * [Código del Botón]
 *   <vs-button @click="btnLoader($event, tuFuncion)">Crear cuenta</vs-button>
 *
 * [Implementación en tu función]
 * [Nota: se puede mostrar un checkbox OK (✓) si se envia "true" como parametro de stopLoader()
 *   tuFuncion(stopLoader) {
 *     ...tu código...
 *     stopLoader()
 *   }
 *
 *
 * [Nota: si se usa fuera de un vx-card, añadir esta clase al contenedor más próximo]
 *  vs-con-loading__container
 *
 */

/**
 * btnLoader
 *
 * emitter - evento o selector
 * action - funcion que se quiere ejecutar
 * bgColor - #000, primary, etc
 * txtColor - #fff, warning, etc

 */
/* eslint-disable */
 import $ from 'jquery'
 export default component => function btnLoader (emitter, action, bgColor, txtColor) {
   const element = typeof (emitter) === 'string' ? document.querySelector(emitter) : emitter.target
   component.$vs.loading({
     background: bgColor || 'primary',
     color: txtColor || '#fff',
     container: element,
     scale: 0.45
   })
   setTimeout(_ => action((showOK = false) => {
     component.$vs.loading.close(element)
     if (showOK) {
       const ok = $(`
         <div class="btnLoader-ok" style="` +
          `background-color: ${bgColor || 'rgba(var(--vs-primary),1)!important'};` +
         'transition: 1.2s;' +
         'position: absolute;' +
         'width:100%;' +
         'top:0;left: 0;' +
         'display:flex;' +
         'height:100%;' +
         'align-items: center;' +
         'justify-content: center;">' +
         '<i style="font-size: 200%" class="' +
         'vs-icon notranslate icon-scale material-icons">check</i></div>')
       $(element).append(ok)
       setTimeout(_ => $('.btnLoader-ok').css('opacity', 0), 1200)
       setTimeout(_ => $('.btnLoader-ok').remove(), 2200)
     }
   }), 300)
 }
 